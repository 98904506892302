const FormMessages = [
	{
		name: "Fred Flintstone",
		email: "fred@bedrock.com",
		message: "Hello, my wife Wilma has decided to run for Bedrock State Senate. We need a messaging strategy, can you please help us?"
	},
	{
		name: "Amanda Gorman",
		email: "amanda@gorman.us",
		message: "When day comes we ask ourselves, where can we find light in this never-ending shade?"
	},
	{
		name: "Jane Jetson",
		email: "jane@spacely-space-rockets.gov",
		message: "Hi there, I want to encourage my fellow citizens of Oribit City to register to vote before intergalactic election day this November. Can you help me orchestrate a campaign?"
	}
]

export default FormMessages
