// import logo from './logo.svg';
import './App.css';

import CircleBar from './containers/CircleBar'

import Footer from './containers/Footer'

const ADJECTIVES = {
  red: "Creative",
  blue: "Progressive",
  gray: "Dedicated",
}

function App() {
  return (
    <div className="App">
      <main className="container">
        <div id="headline" className="row mt-2 mt-md-5" >
          <div className="col">
          <h1 className="header-text">Sulli Norris-Cole</h1>
            <h2 className="subheader-text">
              {/* Progressive Digital Communications Principle<br/> */}
            Partner, SBDigital</h2>
          </div>
        </div>
        <div className="row">
          <div className="col col-6-md d-flex justify-content-center">
            {/* TODO: need the local image file */}
            <img 
              id="profile-image"
              src="profile.jpg" 
              alt="It's me."
              className="rounded-circle"
              // style={imageStyles} 
            />
          </div>
          <div className="col col-6-md">
            <div>
              <h1 className="header-text">Hello,</h1>
              <h2 className="subheader-text">a bit about me:</h2>
            </div>
            <CircleBar {...ADJECTIVES} />
            <p>
              <strong>Sulli Norris-Cole</strong> is a Partner at SBDigital and joined the firm in 2015 and helped build the company from just three people in a small office in DC to the expanded, innovative digital firm of over 30  people from across the country that it is today.
            </p>
            <p>
            Sulli's work has primarily focused on the Independent Expenditure side, and for the past several years, she has been primarily working with national organizations like Forward Majority and EarthJustice Action, as well as multi-state affiliate organizations like Planned Parenthood and the League of Conservation Voters, in addition to local organizations.
            </p>
            <p>
            Sulli's work in pushing progressive causes forward both electorally and through legislative accountability from city councils up to the federal government supports a holistic approach to political and public affairs communication.
            </p>
            <p>
            Sulli has worked to lead SBDigital's efforts to adopt the newest and most effective methods of targeting and digital outreach across many platforms using the latest advertising opportunities available. She is continuously working to bridge the gap between political goals and testing new approaches in technology and communication tactics. Sulli believes in approaching every universe whether a group of voters, specific lawmakers or other stakeholders, as its own individual campaign, requiring a thoughtful and tailored approach that serves the overall goal, but is built specifically for each critical audience.
            </p>
          </div>
        </div>
      </main>

      <Footer/>
    </div>
  );
}

export default App;
