import ColoredCircle from '../components/ColoredCircle'

const barStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  marginBottom: "4rem",
}

const CircleBar = (props) => {
    return (
      <div style={barStyles}>
        <ColoredCircle content={props.red} color="red"/>
        <ColoredCircle content={props.blue} color="blue"/>
        <ColoredCircle content={props.gray} color="gray"/>
      </div>
      )
    }
    
export default CircleBar
    