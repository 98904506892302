import ContactForm from '../components/ContactForm'

const Footer = (props) => {
    return (
      <footer>
            <ContactForm />
      </footer>
        )
    }
    
export default Footer
    