const circleSize = 110
// const circleRadius = circleSize / 2

const circleStyle = {
  width: circleSize,
  height: circleSize,
  // "-webkit-border-radius": circleRadius,
  // "-moz-border-radius": circleRadius,
  // borderRadius: circleRadius,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize:"13px",
  textTransform: "uppercase",
  fontFamily: "'open sans',sans-serif",
  color: "white",
}

const redCircle = {
  ...circleStyle,
  background: "#D30B0D",
}

const blueCircle = {
  ...circleStyle,
  background: "#428BCA",
}

const grayCircle = {
  ...circleStyle,
  background: "#CBCACA",
  color: "#4d4d4d",
}

const ColoredCircle = (props) => {
    let setStyle = grayCircle
    if (props.color === "red") {
        setStyle=redCircle
    } else if (props.color === "blue") {
        setStyle = blueCircle
    } 
    return (
        <div className="rounded-circle" style={setStyle}>{props.content}</div>
        )
    }
    
export default ColoredCircle
    