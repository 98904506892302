// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import FormMessages from '../data/FormMessages.js'

function ContactForm() {
  
  const [state, handleSubmit] = useForm("xeqnvnrz");
  if (state.succeeded) {
      return <p>Thanks!</p>;
  }
  state.preill = FormMessages[[Math.floor(Math.random() * FormMessages.length)]]

  return (
      <form id="contact-form" onSubmit={handleSubmit}>
      <legend><h1 className="header-text">Get in touch</h1></legend>
      <input type="hidden" name="subject" value="Message from sullinorris.com!" />
      <label htmlFor="name">
        Name:
      </label>
      <input
        id="contact-form-name"
        type="name" 
        name="name"
        placeholder={state.preill.name}
      />
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
      <label htmlFor="email">
        Email:
      </label>
      <input
        id="contact-form-email"
        type="email" 
        name="email"
        placeholder={state.preill.email}
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <label htmlFor="message">
        Message:
      </label>
      <textarea
        id="contact-form-message"
        name="message"
        placeholder={state.preill.message}
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <button type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}


export default ContactForm




// // form for formspree.io
// import React from "react";
// import FormMessages from '../data/FormMessages.js'

// export default class MyForm extends React.Component {
//   constructor(props) {
//     super(props);
//     this.submitForm = this.submitForm.bind(this);
//     this.state = {
//       status: "",
//       prefill: FormMessages[[Math.floor(Math.random() * FormMessages.length)]]
//     };
//   }

//   render() {
//     const { status } = this.state;
//     let formPrefill = this.state.prefill
//     return (
//       <form
//           id='contact-form'
//           onSubmit={ this.submitForm }
//           action="https://formspree.io/f/xeqnvnrz"
//           method="POST"
//         >
//         <fieldset>
//           <legend><h1 className="header-text">Get in touch</h1></legend>
//           <label>Name:</label>
//           <input required type="text" name="name" placeholder={ formPrefill.name }/>
//           <label>Email:</label>
//           <input required type="email" name="email" placeholder={ formPrefill.email }/>
//           <label>Message:</label>
//           <textarea required rows="5" name="message" id="message" placeholder={ formPrefill.message }></textarea>
//           <input type="text" name="_gotcha" style={{display:"none"}} />
//           <input type="hidden" name="_subject" value="Message from sullinorris.com!" />
//           { /* @TODO - add CORS protection https://help.formspree.io/hc/en-us/articles/360038664534-Restrict-to-Domain */}
//           {status === "SUCCESS" ? <p>Thanks!</p> : <button>Send</button>}
//           {status === "ERROR" && <p>Ooops! There was an error.</p>}
//         </fieldset>
//       </form>
//     );
//   }

//   submitForm(ev) {
//     ev.preventDefault();
//     const form = ev.target;
//     const data = new FormData(form);
//     const xhr = new XMLHttpRequest();
//     xhr.open(form.method, form.action);
//     xhr.setRequestHeader("Accept", "application/json");
//     xhr.onreadystatechange = () => {
//       if (xhr.readyState !== XMLHttpRequest.DONE) return;
//       if (xhr.status === 200) {
//         form.reset();
//         this.setState({ status: "SUCCESS" });
//       } else {
//         this.setState({ status: "ERROR" });
//       }
//     };
//     xhr.send(data);
//   }
// }
